.<template>
  <div>
    <Header />

    <section class="container container-space-y-m">
      <div class="intro">
        <div class="h3 mb-4"
             align="center">公司介绍</div>
        <p>极汇互动（广东）信息科技有限公司-----企业一站式短信供应商</p>
        <p>极汇互动自成立以来一直专注于移动信息服务领域，是国内了领先的企业信息化鼓舞提供商，与电信运营商合作提供行业移动信息服务和个人移动信息服务，是中国移动SI（服务集成商）的战略合作伙伴，具备集成型SI国内三大运营商的全业务代理资质。</p>
        <p>极汇互动现与京东等各大平台都有紧密的合作，一直致力于企业信息化服务解决方案、企业系统集成的解决方案和短信息等服务方案。企业的个性化要求让多元的合作商产品和解决方案应运而生。</p>
        <p>目前为止极汇互动（广东）信息科技有限公司已为多家企业信息系统提供产品和技术服务，受到客户的一致赞誉。极汇互动不断加强信息技术商家合作深入了解各用户产品与需求，针对客户不同的需求提供最适合的解决方案。客户不仅能从极汇互动得到符合专业需求的产品和服务。极汇互动（广东）信息科技有限公司不仅依据自身的实力和高质量的服务赢得客户的信赖，更拥有众多的战略合作伙伴提供强有力的支持。</p>
        <p>极汇互动（广东）信息科技有限公司依靠强大的技术支持和良好的售后服务为客户提供全方案的服务需求方案。</p>
      </div>
    </section>

    <section class="container-space-b-s">
      <div class="container">
        <div class="d-flex">

          <div class="flex-shrink-0 mr-5">
            <baidu-map class="baidu-map-view"
                       :scroll-wheel-zoom="true"
                       @ready="mapReady">
              <!-- bm-marker 就是标注点 定位在point的经纬度上 跳动的动画 -->
              <bm-marker :position="point"
                         animation="BMAP_ANIMATION_BOUNCE"
                         @click="showInfoWindow = true">
              </bm-marker>
              <bm-info-window title="极汇互动(广东)信息科技有限公司"
                              :width="350"
                              :position="point"
                              :show="showInfoWindow"
                              @close="showInfoWindow = false">
                <p>地址：广东省广州市黄埔区联和街道广汕3路31号</p>
                <p>联系电话：020-86574220</p>
              </bm-info-window>
            </baidu-map>
          </div>

          <div class="flex-grow-1 contact-address">
            <div class="mb-5">
              <h3 class="mb-2"><i class="contact-address-title"></i> 办公地址/Office Address</h3>
              <p>广东省广州市黄埔区联和街道广汕3路31号</p>
            </div>
            <div class="contact-address-item">
              <h3 class="mb-2"><i class="fa fa-phone"></i>联系方式/Phone</h3>
              <p>服务热线：020-86574220</p>
            </div>
          </div>

        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'ContactUs',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      point: {},
      showInfoWindow: true // 显示信息窗口
    }
  },

  methods: {
    mapReady ({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(113.44308, 23.194689)
      map.centerAndZoom(this.point, 17)
    }

  }
}
</script>

<style lang="scss" scoped>
.contact-address {
  background: linear-gradient(90deg, #000 50%, #fff 100%);
  padding: 40px 35px;

  h3 {
    color: #fff;
  }

  p {
    color: #c5c5c5;
  }
}
.contact-address-line {
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.intro {
  position: relative;

  p {
    font-size: 1rem;
    text-indent: 2rem;
    margin-bottom: 6px;
  }

  article {
    max-width: 800px;
    padding: 20px;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0px 0px 40px rgba(29, 58, 83, 0.15) !important;
  }
}

.intro-title {
  font-weight: 600;
  font-size: 30px;
  color: #000;
  line-height: 47px;
  text-align: center;
  margin-bottom: 18px;
}

.intro-content {
  line-height: 25px;
  font-size: 18px;
  color: #4c4c4c;
}

.baidu-map-view {
  width: 500px;
  height: calc(100vh - 390px);
}
</style>
